<template>
  <svg viewBox="0 0 272 272" xmlns="http://www.w3.org/2000/svg" :width="widthIcon" :height="heightIcon" :fill="colorIcon">
    <rect height="8" width="136" x="68" y="68"/>
    <rect height="8" width="136" x="68" y="92"/>
    <rect height="8" width="136" x="68" y="116"/>
    <rect height="8" width="136" x="68" y="140"/>
    <rect height="8" width="136" x="68" y="164"/>
    <rect height="8" width="136" x="68" y="188"/>
    <rect height="8" width="136" x="68" y="212"/>
    <path d="M216,40H192V16l-8-8H48V264H224V48Zm0,216H56V16H184V48h32Z"/>
    <rect height="8" width="136" x="68" y="68"/>
    <rect height="8" width="136" x="68" y="92"/>
    <rect height="8" width="136" x="68" y="116"/>
    <rect height="8" width="136" x="68" y="140"/>
    <rect height="8" width="136" x="68" y="164"/>
    <rect height="8" width="136" x="68" y="188"/>
    <rect height="8" width="136" x="68" y="212"/>
    <path d="M184,8V48h40Zm8,32V27.31L204.69,40Z"/>
    <rect height="8" width="136" x="68" y="68"/>
    <rect height="8" width="136" x="68" y="92"/>
    <rect height="8" width="136" x="68" y="116"/>
    <rect height="8" width="136" x="68" y="140"/>
    <rect height="8" width="136" x="68" y="164"/>
    <rect height="8" width="136" x="68" y="188"/>
    <rect height="8" width="136" x="68" y="212"/>
  </svg>
</template>

<script>
export default {
  name: "SchoolIcon",
  props: {
    widthIcon: {
      type: String,
      default: '30'
    },
    heightIcon: {
      type: String,
      default: '30'
    },
    colorIcon: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style scoped>

</style>
