export const ru = {
    about: 'О нас',
    main: 'Main',
    profile: 'Профиль',

    back: 'Назад',
    lang_ru: 'Русский',
    lang_en: 'Английский',
    briefing: 'Инструктаж',
    pre_shift_Examiner: 'Предсменный экзаменатор',
    attestation_through: 'Аттестация через',
    tests_completed: 'Тестов выполнено',
    day: 'день',
    get_started: 'Начать работу',
    notifications: 'Уведомления',
    education: 'Обучение',
    achievements: 'Достижения',
    logOut: 'Выйти',

    Main_page: 'Главная страница',
    About_page: 'О нас',
    NotFoundPage: 'Страница не найдена',
    text_MyErrorPage: 'Произошла непредвиденная ошибка, перезагрузите страницу',
    text_btn_reload: 'Перезагрузить страницу',
    logIn: 'Войти',
    Enter_your_name: 'Введите имя',
    Enter_password: 'Введите пароль',
    You_are_not_registred: 'Вы не зарегистрированы',
    error: 'Ошибка',
    You_entered_the_wrong_username_or_password: 'Вы ввели неправильный логин или пароль',

    authorization_form: 'Форма авторизации',
    edit_profile: 'Редактировать профиль',
    First_name: 'Имя',
    Last_name: 'Фамилия',
    Age: 'Возраст',
    Currency: 'Валюта',
    Country: 'Страна',
    username: 'Логин',
    avatar: 'Аватар',
    Profile: 'Профиль',
    cancel: 'Отмена',
    save: 'Сохранить',
    Error_field: 'Ошибка, поле не должно быть пустым',
    articles: 'Статьи',
    comments: 'Комментарии',
    no_comments: 'Комментарии отсутствуют',
    enter_comment_text: 'Введите текст комментария',
    send_comment: 'Отправить',
    read_more: 'Читать далее...',
    back_to_articles: 'Назад к статьям'
}
