<template>
  <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
       :width="widthIcon" :height="heightIcon" :fill="colorIcon" viewBox="0 0 566.1 566.1" style="enable-background:new 0 0 566.1 566.1;" xml:space="preserve"
  >
<g>
	<g>
		<path d="M492.469,42.075L387.281,0l-82.238,202.725c40.163,3.825,76.5,21.038,105.188,45.9L492.469,42.075z"/>
    <path d="M262.968,202.725L180.731,0l-107.1,42.075l84.15,206.55C186.468,223.763,222.806,208.462,262.968,202.725z"/>
    <path d="M284.006,221.85c-95.625,0-172.125,76.5-172.125,172.125S188.381,566.1,284.006,566.1s172.125-76.5,172.125-172.125
			S377.719,221.85,284.006,221.85z M350.943,489.6l-66.938-43.987L217.068,489.6l28.688-76.5l-57.375-38.25h66.938l28.688-76.5
			l28.688,76.5h66.938l-57.375,38.25L350.943,489.6z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "StarMedalIcon",
  props: {
    widthIcon: {
      type: String,
      default: '30'
    },
    heightIcon: {
      type: String,
      default: '30'
    },
    colorIcon: {
      type: String,
      default: 'black'
    }
  }
}
</script>

<style scoped>

</style>
