export const en = {
    about: 'About',
    main: 'Main',
    profile: 'Profile',


    back: 'Back',
    lang_ru: 'Russia',
    lang_en: 'English',
    briefing: 'Briefing',
    pre_shift_Examiner: 'Pre-shift examiner',
    attestation_through: 'Attestation through',
    tests_completed: 'Tests completed',
    day: 'day',
    get_started: 'Get Started',
    notifications: 'Notifications',
    education: 'Education',
    achievements: 'Achievements',

    Main_page: 'Main page',
    About_page: 'About page',
    NotFoundPage: 'Not Found Page',
    text_MyErrorPage: 'An unexpected error occurred, please reload the page',
    text_btn_reload: 'Reload page',
    logIn: 'logIn',
    Enter_your_name: 'Enter your name',
    Enter_password: 'Enter password',
    You_are_not_registred: 'You are not registred',
    error: 'Error',
    You_entered_the_wrong_username_or_password: 'You entered the wrong username or password',
    logOut: 'Logout',
    authorization_form: 'Authorization form',
    edit_profile: 'Edit profile',
    First_name: 'First name',
    Last_name: 'Last name',
    Age: 'Age',
    Currency: 'Currency',
    Country: 'Country',
    username: 'Username',
    avatar: 'Avatar',
    Profile: 'Profile',
    cancel: 'Cancel',
    save: 'Save',
    Error_field: 'Error, field must not be empty',
    articles: 'Articles',
    comments: 'Comments',
    no_comments: 'No comments',
    enter_comment_text: 'Enter comment text',
    send_comment: 'Send',
    read_more: 'Read more...',
    back_to_articles: 'Back to articles'
}
